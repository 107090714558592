import React, { useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import '../common/css/global.css';
import './App.css';
import * as project from "../common/api/projects";
import * as impact from '../common/api/impact';
import MagEmaStnLandingPage from "./pages/MagEmaStnLandingPage";
import ImpactPage from "./pages/ImpactPage";
import merchantLogo from './media/MAG_MAN_FLAT_REV_RGB.png';

function App() {
  const merchantCode = 'AIRPORT_MAG';
  const [fetchedImpact, setFetchedImpact] = useState();

  const quoteData = {
    name: 'Chris Woodroofe',
    position: 'Managing Director, Manchester Airport',
    text: <>Our Climate Friendly Traveller tool, powered by CarbonClick, is just one way we can all contribute to a reduction of CO<sub>2</sub> levels, and by offsetting your flight through Manchester Airport, you’ll also be supporting important causes in the UK and abroad</>
  };

  useEffect(() => {
    impact.merchants(merchantCode, "en_GB", ['flightsImpact'] )
      .then(response => response.json())
      .then(body => {
        setFetchedImpact(body)
      })
      .catch(error => {
        console.error(error);
        setFetchedImpact(error)
      })
  }, []);

  return (
    <>

      <Switch>
        {/* DO NOT DELETE IMPACT - The impact page should always be kept, even if not specified in a design */}
        <Route
          exact
          path={`/impact`}
          key="app-mag-impact"
        >
          <ImpactPage merchantCode={merchantCode} />
        </Route>
        {/* DO NOT DELETE IMPACT - The impact page should always be kept, even if not specified in a design */}
        <Route
          path={`/`}
          key="app-mag-landing"
        >
          <MagEmaStnLandingPage merchantLogo={merchantLogo} fetchedImpact={fetchedImpact} merchantCode={merchantCode} quoteData={quoteData}/>
        </Route>
      </Switch>
    </>
  );
}

export default App;
