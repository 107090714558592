import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as Sentry from "@sentry/browser";
import { sentryOptions } from "../../sentryConfig";
import {initialiseGoogleAnalyticsWithCustomerProdTrackingId} from "../common/util/ccAnalyticsV2";

const app = (
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>
)

Sentry.init(sentryOptions);

initialiseGoogleAnalyticsWithCustomerProdTrackingId("AIRPORT_MAG",null);

const appElement = document.getElementById('airport-mag');
if (appElement) {
  ReactDOM.render(app, appElement);
}

// Cookie acceptance code
setTimeout(() => {
    const banner = document.getElementById("cookie-notice-banner");
    if (!window.localStorage?.getItem("cookie-notice-seen")) {
        const button = document.getElementById("cookie-notice-hide");
        if(button){
          button.addEventListener('click', () => {
            window.localStorage?.setItem("cookie-notice-seen", 'true');
            banner.style.bottom = "-10vh";
            setTimeout(() => banner.remove(), 2000);
          });
          banner.style.removeProperty("visibility");
          setTimeout(() => banner.style.removeProperty("bottom"), 100);
        }

    } else banner?.remove();
}, 2000);
